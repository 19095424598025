import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";

import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import { getAllSuppliersAction } from "../store/supplier/actions";
import React, { useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

export const purchaseDropDownRoutes = [
  {
    caption: "Overview",
    path: "/purchase",
    onClick: () => {
      console.log("Overview:00");
    },
  },
  {
    caption: "Request For Quotation",
    path: "/purchase/rfq",
  },
  {
    caption: "Purchase Order",
    path: "/purchase/po",
  },
  {
    caption: "Suppliers",
    path: "/purchase/suppliers",
    onClick: () => {
      console.log("Overview:00");
    },
  },
];
const DashRoutes = () => {
  const { auth, supplier } = useSelector((state: any) => state);
  // const token = store?.get("authToken");

  const allowedRoles = ["admin", "finance", "finance-manager", "dev"];
  const isAuthenticated = auth.token;
  const userRole = auth.user?.role;

  const dispatch = useDispatch();
  useEffect(() => {
    auth?.token &&
      getAllSuppliersAction(auth?.token, supplier?.query || "?")(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, supplier.new]);
  let authent = { token: true };
  const pagesArray = [
    {
      caption: "Overview",
      path: "/purchase",
      onClick: () => {
        console.log("Overview:00");
      },
    },
    {
      caption: "Orders",
      // path: "/purchase/rfq",
      onClick: () => {
        console.log("Overview:00");
      },
      sub: [
        {
          caption: "Request For Quotation",
          path: "/purchase/rfq",
          onClick: () => {
            console.log("Overview:00");
          },
          bread: [
            {
              href: "/purchase",
              title: (
                <>
                  <ArrowLeftOutlined />
                  <span>Orders</span>
                </>
              ),
            },
            { title: "RFQ" },
          ],
        },
        {
          caption: "Purchase Order",
          path: "/purchase/po",
          onClick: () => {
            console.log("Overview:00");
          },
        },
      ],
    },
    {
      caption: "Suppliers",
      path: "/purchase/suppliers",
      onClick: () => {
        console.log("Overview:00");
      },
    },
  ];
  if (isAuthenticated && allowedRoles.includes(userRole)) {
    return (
      <DashLayout
        allowSearch={true}
        nav={
          <TopNav
            nav={listManages?.find(
              (item) => item.title.toLowerCase() === "Purchase".toLowerCase()
            )}
            pages={pagesArray}
          />
        }
        selectedNav={2}
      >
        <Outlet />
      </DashLayout>
    );
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default DashRoutes;
