import React from "react";
import { Space, Tooltip } from "antd";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import type { ColumnsType, TableProps } from "antd/es/table";
import RequestGridCard from "./RequestGridCard"

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Type",
    dataIndex: "type",

    // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Name",
    dataIndex: "name",

    // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    // onFilter: (value: string, record:any) => record.name.indexOf(value) === 0,

    // sorter: (a, b) => a.model.length - b.model.length,
    // sortOrder: sortedInfo.columnKey === 'address' ? sortedInfo.order : null,
    // sortDirections: ["descend"],
  },
  {
    title: "Email",
    dataIndex: "email",
    defaultSortOrder: "descend",
    // sorter: (a, b) => a.age - b.age,
  },
  {
    title: "Tin",
    dataIndex: "tin",

    // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Status",
    dataIndex: "status",

    // onFilter: (value: string, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <IconButton aria-label="delete" color="success">
          <RemoveRedEyeIcon />
        </IconButton>
        <IconButton aria-label="delete" color="secondary">
          <BorderColorIcon />
        </IconButton>
        <Tooltip placement="bottomLeft" title={"Delete"}>
          <IconButton aria-label="delete" color="error">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Space>
    ),
  },
];

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const App = (props: any) => {
  // console.log("props data 1 2", props.data2)
  return props?.request
    ? props?.data?.map(
        (d: any) =>
   <RequestGridCard data={ d } />
      )
    : props?.data?.map((d: any) => {
        return <RequestGridCard data={d} />;
      });
};

export default App;
