import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createServiceQuotation = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/quot`, data, token);
};
export const getAllServiceQuotation = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/quot${query}`, token);
};

export const getOneServiceQuotation = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/quot${query}`, token);
};

export const addOneItemServiceQuotation = async (
  id: string,
  data: any,
  token: string
) => {
  return HttpRequest.update(`${SERVER_URL}/quot/additem/${id}`, data, token);
};

export const updateServiceQuotation = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/quot/one/${itemId}`,
    data,
    token
  );
};

export const deleteServiceQuotation = async (itemId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/quot/one/${itemId}`, token);
};
export const deleteQuotationItemService = async (
  itemId: string,
  token: string
) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/quotitem/one/${itemId}`,
    token
  );
};

export const updateQuotationItemService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/quotitem/one/${itemId}`,
    data,
    token
  );
};
//Quotations Supplier
export const getAllServiceQuotationSupplier = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/quotSupplier${query}`, token);
};

//Quotations Supplier
export const getAllQuotationFilters = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/quotSupplier/status`, token);
};

export const createServiceQuotationRfq = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/quotSupplier`, data, token);
};

export const updateServiceQuotationRfq = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/quotSupplier/one/${itemId}`,
    data,
    token
  );
};

export const deleteServiceQuotationToSupplier = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/quotSupplier/one/${itemId}`,
    data,
    token
  );
};

export const getOneServiceQuotationRfq = async (
  itemId: string,
  token: string
) => {
  return HttpRequest.get(`${SERVER_URL}/quotSupplier/one/${itemId}`, token);
};

export const getOneServiceQuotationRfqSent = async (itemId: string) => {
  return HttpRequest.get(`${SERVER_URL}/quotSupplier/sent/${itemId}`);
};

export const sendEmail = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/send/rfq`, data, token);
};

export const getOneQuotationItemService = async (
  quotId: string,
  token: string
) => {
  return HttpRequest.get(`${SERVER_URL}/quotItem/one/${quotId}`, token);
};
