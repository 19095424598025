import React, { useEffect, useState } from "react";
import { Stack, Button } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllChannelAction,
  getAllShopAction,
} from "../../../../store/channel/actions";
import { searchFuction } from "../../../../utils/converter";
const { Option } = Select;

const RepairFiltering = (props: any) => {
  const { auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [selectedhannel, setSelectedChannel] = useState("");
  const [shopFilter, setShopFilter] = useState([]);

  const resetFilter = () => {
    if (selectedFilters) {
      setSelectedChannel("");
      props?.setSelectedShop("");
    }
  };

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  useEffect(() => {
    if (auth?.token && selectedhannel) {
      getAllShopAction(auth?.token, `channel=${selectedhannel}`)(dispatch);
    }
  }, [auth, selectedhannel, dispatch]);

  return (
    <div className="pr-4 pt-2 pb-6">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          spacing={1}
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            startIcon={
              selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
            }
            onClick={() => {
              setSelectedFilters(!selectedFilters);
              resetFilter();
            }}
          >
            Filter
          </Button>

          {selectedFilters && (
            <Stack
              spacing={1}
              direction="row"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Select
                showSearch
                style={{ width: 200, height: 40 }}
                allowClear
                placeholder="Select Channel"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                loading={channel.isFetching}
                disabled={channel.isFetching}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={(value: any) => {
                  !value && setSelectedChannel("");
                  value && setSelectedChannel(value);
                }}
              >
                {channel?.all?.data?.map((d: any, index: any) => (
                  <Option Key={index} value={d?._id} className="capitalize">
                    {d?.name}
                  </Option>
                ))}
              </Select>
              {selectedhannel && (
                <Select
                  showSearch
                  onSearch={(value: any) =>
                    searchFuction(
                      value,
                      channel?.allShop?.data,
                      (option: any, val) =>
                        option?.name?.toLowerCase().includes(val.toLowerCase()),
                      setShopFilter
                    )
                  }
                  style={{ width: 200, height: 40 }}
                  allowClear
                  placeholder="Filter By Shop"
                  optionFilterProp="children"
                  filterOption={false}
                  loading={channel.isFetching}
                  disabled={channel.isFetching}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")

                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  onChange={(value: any) => {
                    !value && props?.setSelectedShop("");
                    value && props?.setSelectedShop(value);
                  }}
                >
                  {shopFilter.length > 0
                    ? shopFilter.map((el: any) => (
                        <Option value={el?._id}>{el?.name}</Option>
                      ))
                    : channel?.allShop?.data?.map((d: any, index: any) => (
                        <Option
                          Key={index}
                          value={d?._id}
                          className="capitalize"
                        >
                          {d?.name}
                        </Option>
                      ))}
                </Select>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default RepairFiltering;
