import React from "react";
import { DatePicker, DatePickerProps, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { getAllNosaleAction } from "../../store/pos/actions";
import ScrollableFrame from "../layout/ScrollableFrame";
import { getActivePrice } from "../../utils/converter";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { getDefaultCurrencyCode } from "../../utils/helper";
// import dayjs from "dayjs";
// import moment from "moment";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const AgentNosalesTable = (props: any) => {
  const { pos, auth, layout, company } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);
  const formattedDate = new Date().toISOString().slice(0, 10);
  const [selectedDate, setSelectedDate] = React.useState<any>(formattedDate);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  console.log("no sales", pos);
  const { id } = useParams();

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (_text, record) => (
        <p>
          {record?.data?.priceListItemId?.product?.model ||
            record?.data?.newProduct ||
            record?.data?.productId?.model}
        </p>
      ),
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 150,
      render: (text, record) => (
        <p>
          {(record?.data?.priceListItemId
            ? text
            : record?.data?.productId?.specs
                ?.map((d: any) => `${d?.label} : ${d?.value}`)
                ?.join("; ")) ?? "N/A"}
        </p>
      ),
    },
    {
      title: `Price (${getDefaultCurrencyCode(company)})`,
      dataIndex: "prices",
      key: "prices",
      render: (text, _record) => <p>{text || "N/A"}</p>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (text, _record) => <p>{text || "-"}</p>,
    },
    {
      title: "Reason",
      dataIndex: "comments",
      key: "comments",
      render: (text, record) => <p>{text || "N/A"}</p>,
    },
  ];

  React.useEffect(() => {
    auth?.token &&
      getAllNosaleAction(
        auth?.token,
        `?&createdBy=${layout.userInfo?._id}`
      )(dispatch);
  }, [auth, dispatch, layout.userInfo?._id]);

  const value = pos?.nosale?.data?.noSales;
  React.useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          date: el?.createdAt?.slice(0, 10),
          specification: `${el?.priceListItemId?.specification
            ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
            ?.join(", ")}`,
          barCode: el?.warehouseProduct?.serialNumber,
          shop: `${el?.shopId?.name} ~ ${el?.shopId?.type}`,
          type: el?.shopId?.type,
          customer: el?.customerId?.name,
          comments: el?.comment,
          quantity: el?.quantity,
          prices: getActivePrice(el?.priceListItemId?.prices)?.toLocaleString(),
          data: el,
        });
      });

    setformData(dataMapper);
  }, [pos?.nosale]);

  // const onChangeDate: DatePickerProps["onChange"] = async (
  //   date: any,
  //   dateString: any
  // ) => {
  //   setSelectedDate(dateString);
  // };

  // const disabledDate = (current: any) => {
  //   if (current && current > moment().endOf("day")) {
  //     return true;
  //   }
  //   return false;
  // };
  const smallDevices = useMediaQuery("(max-width:1320px)");
  return (
    <div className="relative">
      {/* <div className="flex items-center justify-between mr-4 mt-4">
        <DatePicker
          onChange={onChangeDate}
          defaultValue={dayjs()}
          style={{ width: 150 }}
          disabledDate={disabledDate}
        />
      </div> */}
      {/* <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={pos?.nosale?.total}
        count={Math.ceil(pos?.nosale?.total / limit)}
      > */}
      <Table
        columns={columns}
        dataSource={formData}
        pagination={false}
        loading={pos?.isFetching}
        scroll={smallDevices ? { x: 1300 } : undefined}
      />
      {/* </ScrollableFrame> */}
    </div>
  );
};

export default AgentNosalesTable;
