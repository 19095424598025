import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

export const StatisticsCard: React.FC<{ data: any; total: number }> = ({
  data,
  total,
}) => {
  const size = {
    width: 500,
    height: 180,
  };

  const CenteredLabel = styled("div")({
    position: "absolute",
    top: "50%",
    left: "32%",
    transform: "translate(-50%, -50%)",
    fontSize: "18px",
    fontWeight: "semiBold",
    color: "#030229",
  });

  const generateColors = (numColors: number): string[] => {
    const baseColor = "#0000FF";
    const colors = ['#605BFF', '#807BFF', '#A09DFF', '#BFBDFF', '#DFDEFF'];
    const step = 255 / (numColors + 1);
    const baseColorInt = parseInt(baseColor.substring(1), 16);
    const generatedColors = [];
    for (let i = 0; i < numColors; i++) {
      const newColorInt = baseColorInt + Math.round(step * (i + 1));
      generatedColors?.push(`#${newColorInt.toString(16)?.padStart(6, "0")}`);
    }
    return [...colors, ...generatedColors];
  };

  const colors = data && data?.length > 0 ? generateColors(data?.length) : generateColors(6);

  return (
    <div style={{ position: "relative", padding: "2px" }}>
      <div className="-ml-24 relative">
        <PieChart
          series={[
            {
              data,
              innerRadius: 65,
            },
          ]}
          colors={colors}
          {...size}
        />
        <CenteredLabel className="flex justify-center items-center">
          <div>

            <p className="text-xcenter text-[#78787c]">Total</p>
            <p className="text-xcenter font-medium text-base">{total}</p>
          </div>
        </CenteredLabel>
      </div>
    </div>
  );
};
