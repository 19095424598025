import { Box, Stack, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Badge, Divider, Tag } from "antd";
import { searchValue } from "../../utils/setColor";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "./../../components/Modals/DeleteModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRequestStockAction } from "../../store/channel/actions";
import { getActiveShop } from "../../utils/converter";
import { getAllStockRequestSentAction } from "../../store/wareHouseOut/actions";

const Component = (props: any) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth, channel, shop } = useSelector((state: any) => state);
  const data = {
    name: props?.data?.shopId?.name || "New RFS",
    status: props.data?.status,
    id: props.data?.requestId,
    product:
      props.data?.list?.map((item: any) => item?.product?.product?.model) || "",
    quantity: props.data?.list?.map((item: any) => item?.qtyRequested) || "0",
  };

  const shopId =
    shop?.selected?._id ||
    getActiveShop(auth?.user?.shop?.assigned)[0]?.shop?._id;

  const deleteModal = () => {
    setVisible(true);
  };
  const deleteRequest = async () => {
    if (auth?.token) {
      await deleteRequestStockAction(
        auth?.token,
        props?.data?._id,
        `?shopId=${shopId}`
      )(dispatch);
      await getAllStockRequestSentAction(
        auth?.token,
        "?status=review&limit=20&page=0"
      )(dispatch);
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="relative custom-backface-visibilty bg-white border-gray-100  py-2 pb-4 min-w-[300px] shadow text-black min-h-[126px] rounded-md !duration-200">
        <Badge.Ribbon
          style={{ padding: "4px" }}
          color={searchValue(data.status)}
          text={<span className="capitalize">{data.status}</span>}
        >
          <Stack
            spacing={2}
            onClick={() => {
              navigate(data.id);
            }}
            sx={{ p: 1 }}
          >
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
              sx={{ width: "100%", paddingLeft: 1 }}
            >
              <Stack spacing={0}>
                <h1 className="text-base font-semibold leading-3 text-gray-900 pb-1">
                  {data?.name}
                </h1>
                <p className="text-sm" style={{ color: "blue" }}>
                  {data?.id}
                </p>
              </Stack>
            </Stack>
            <Divider />
            <Box sx={{ width: "100%" }}>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems="flex-end"
                sx={{ width: "100%", paddingLeft: 1 }}
              >
                <Stack spacing={0}>
                  <p className="text-xs">
                    Requested By:{" "}
                    <span className="font-semibold text-xs">
                      {props?.data?.createdBy?.names}
                    </span>
                  </p>

                  {props?.data?.status === "done" && (
                    <>
                      <p className="text-xs">
                        Approved By:{" "}
                        <span className="font-semibold text-xs">
                          {props?.data?.approvedBy?.names}
                        </span>
                      </p>
                      <p className="text-xs">
                        Approved On:{" "}
                        <span className="font-semibold text-xs">
                          {props?.data?.updatedAt?.split("T")[0]}
                        </span>
                      </p>
                    </>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Stack>
          {["request", "review"].includes(props?.data?.status) && (
            <Stack
              direction="row"
              alignItems="flex-end"
              spacing={0}
              className="absolute right-2 bottom-0"
            >
              <IconButton aria-label="delete" size="small">
                <DeleteIcon fontSize="inherit" onClick={deleteModal} />
              </IconButton>
            </Stack>
          )}
        </Badge.Ribbon>
      </div>
      <DeleteModal
        visible={visible}
        onOk={deleteRequest}
        isLoading={channel.isFetching}
        onCancel={handleCancel}
        itemName="Stock Request"
      />
    </>
  );
};

export default Component;
