import react, { useEffect, useState } from "react";
import { Select, Switch, Drawer, Form, ColorPicker, Button,Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { SketchPicker } from "react-color";
import * as piIcons from "react-icons/pi";
import * as ciIcons from "react-icons/ci";
import * as bsIcons from "react-icons/bs";
import * as mdIcons from "react-icons/md";
import light from "../../../../assets/images/setting/Dashboard (2) 1.png";
import dark from "../../../../assets/images/setting/Group 133597.png";
import Theme from "./theme";
import { useDispatch, useSelector } from "react-redux";
import { myLayoutActions } from "../../../../store/layout";
import { activeSelectedTheme } from "../../../../store/layout/actions";
import MoreTheme from "./moreTheme";
import SettingComponent from "../security/SettingComponent";
import { getOneThemeAction, getPreferenceAction, getThemeAction, updatePreferenceAction, updateThemeAction } from "../../../../store/setting/actions";
const Preferences = () => {
  const {auth,setting,layout}=useSelector((state:any)=>state)
  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)"
    },
    border: {
      border: "0.5px solid rgba(15, 15, 71, 0.25)"
    },
    themeBorder: {
      border: "2px solid #605BFF",
      borderRadius: "5px"
    },
    nonThemeBorder: {
      border: "0.2px solid #03022980",
      borderRadius: "5px"
      // padding:"7px"
    }
  };
  const [themeMode, setThemeMode] = useState("light");
  const [currentColor, setCurrentColor] = useState("#ffdd");
  const themeId=setting?.preference?.data?.themeId?._id;
  const dispatch=useDispatch();
  const handleChange = (color: any) => {
    setCurrentColor(color.hex);
  };
  const preferenceId=setting?.preference?.data?._id;
  const isNofication=setting?.preference?.data?.notification;
  const themeNames=setting?.preference?.data?.themeId?.name;
  const SwitchNotifications = async(isNofication:any) => {
    auth?.token && 
   (await updatePreferenceAction(auth.token, preferenceId, {notification:isNofication})(dispatch));
  };
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 }
  };

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };
  useEffect(() => {
    auth?.token && getPreferenceAction(auth?.token)(dispatch);
   auth?.token && getThemeAction(auth?.token)(dispatch)
  }, [auth.token, dispatch]);
 

  useEffect(() => {
    if (auth?.token) {
      getOneThemeAction(auth?.token, themeId as string)(dispatch);
    }
  }, [auth, themeId, dispatch]);
  const handleThemeClick=async(ThemeName:any)=>{
    activeSelectedTheme(ThemeName)(dispatch)
    auth?.token && await updateThemeAction(auth.token, themeId, {name:ThemeName})(dispatch);
   await  getPreferenceAction(auth?.token)(dispatch)
  }
  return (
    <>
      <h1 className="text-[#030229] font-medium text-lg">Preferences</h1>
      <p className="text-[#030229d8] text-sm font-normal">
        Change how Untitled UI looks. language of the platform and other system
        preference.
      </p>
      <div
        className="bg-white mt-5 h-[82vh] divide-y rounded-md px-6 py-8"
        style={styles.customShadow}
      >
        <SettingComponent
          title={"Language"}
          details={"Change The language of the platform"}
          btn={ <Select
            defaultValue={{ value: "english", label: "English" }}
            style={{ width: 150 }}
            // onChange={handleChange}
            options={[
              { label: "Kinyarwnda", value: "kinyarwanda" },
              { label: "Swahili", value: "swahili" },
              { label: "French", value: "french" }
            ]}
          />}
        />
        <Drawer
          title=<span className="text-[#605BFF] pl-10 text-lg font-semibold">
            Customize Theme
          </span>
          width={800}
          placement="right"
          onClose={onClose}
          open={open}
        >
        <div className="ml-5">
          <div>
            <h1 className="text-[#030229] text-base font-medium">Layout</h1>
            <p className="text-[#03022980] text-sm font-normal pt-3">
              Choose Your layout
            </p>
          </div>
          <MoreTheme/>
          </div>
        </Drawer>
        <div className="mt-8 pt-6">
          <div className="flex gap-10 h-[12rem]">
            <div>
              <h1 className="text-[#030229] font-medium text-lg">
                Interface Theme
              </h1>
              <p className="text-[#030229d8] text-sm font-normal pt-2">
                Select to customize your UI Theme
              </p>
              <p
                className="text-[#605BFF] text-base pt-3 flex gap-1 cursor-pointer"
                onClick={showDrawer}
              >
                {" "}
                <piIcons.PiArrowSquareUpRightLight size={22} /> More theme
                Customization
              </p>
            </div>
            <Theme
              name={"light"}
              icon={mdIcons.MdOutlineLightMode}
              iconColor="[#605BFF]"
              themeImage={light}
              isSelected={layout?.selectedTheme === "light"}
              onClick={() => handleThemeClick("light")}
            />
            <Theme
              name={"Dark"}
              icon={ciIcons.CiDark}
              iconColor="[#605BFF]"
              themeImage={dark}
              isSelected={layout?.selectedTheme==="dark"}
              onClick={()=>handleThemeClick("dark")}
            />
          </div>
        </div>
        <SettingComponent
          title={"Accent Color"}
          details={"Select your application accent color (purple blue)"}
          btn={  <Form
            name="validate_other"
            {...formItemLayout}
            onFinish={onFinish}
            style={{ maxWidth: 600 }}
          >
            <Form.Item name="color">
              <ColorPicker
                showText={(color) => <span>{color.toHexString()}</span>}
                className="px-3 py-2"
              />
            </Form.Item>
          </Form>}
        />
         <SettingComponent
          title={"Font style"}
          details={"Select your application font style (Poppins)"}
          btn={ <Select
            defaultValue={{ value: "poppins", label: "Poppins" }}
            style={{ width: 150 }}
            // onChange={handleChange}
            options={[
              { label: "san-sanf", value: "san-sanf" },
              { label: "poppins", value: "Poppins" }
            ]}
          />}
        />
           <SettingComponent
          title={"Notifications"}
          details={"Turn on/off notifications"}
          btn={  <span style={styles.border} className="rounded-[8px] py-1 px-4">
          {" "}
         {setting?.isFetching? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />:isNofication ? "On" : "Off"}
          <Switch  className="ml-2" onClick={SwitchNotifications} />
        </span>}
        />

      </div>
    </>
  );
};
export default Preferences;

