import React, { useEffect, useState } from "react";
import { authActions } from "../../store/auth";
import { useDispatch } from "react-redux";
import { getUserprofileAction } from "../../store/auth/authActions";
import store from "store";
import { useNavigate } from "react-router-dom";

const SplashPage = () => {
  const [typedText, setTypedText] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const textToType = "SΛMPHONE";
  const typingDelay = 100; // Delay between typing each character

  useEffect(() => {
    let currentText = "";
    let currentIndex = 0;

    const typingInterval = setInterval(() => {
      if (currentIndex < textToType.length) {
        currentText += textToType[currentIndex];
        setTypedText(currentText);
        currentIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, typingDelay);

    return () => {
      clearInterval(typingInterval);
    };
  }, []);

  const token = store.get("authToken");

  useEffect(() => {
    if (token !== undefined) {
      getUserprofileAction(token, navigate)(dispatch);
      dispatch(authActions.setToken({ token }));
    }
  }, [dispatch, token]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white">
      <h1 className="text-7xl font-light text-black font-arial">{typedText}</h1>
    </div>
  );
};

export default SplashPage;
