import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Divider, Form, Input, notification, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import WarehouseOutTable from "../../../../components/tables/WarehouseOutTable";
import WarehouseOutTableView from "../../../../components/tables/WarehouseOutTableView";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Stack } from "@mui/material";
import { Spin, Tag, Modal } from "antd";
import {
  getAllWarehouseOutAction,
  getAllWarehouseActionBySN,
  updateStockOutAction,
  getAllWarehouseOutItemsAction,
  updateOneStockOutDetailsAction,
  createShippingDetailsAction,
} from "../../../../store/wareHouseOut/actions";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import LoadingButton from "@mui/lab/LoadingButton";
import ShopFromDrower from "./ShopFromDrower";
// import customParseFormat from "dayjs/plugin/customParseFormat";
// import type { DatePickerProps } from "antd";
// import dayjs from "dayjs";
import {
  createDeliveryActivityAction,
  createOtpActions,
  getDelivererAction,
  getOneDelivererAction,
  getTrackingTypeAction,
  updateParkingAction,
} from "../../../../store/delivery/actions";
import CreateDelivererForm from "./createDelivererForm";
import { getOneCompanyAction } from "../../../../store/setting/company/actions";
import VerifyOtpForm from "./verifyOtpForm";
import { COMPANY_PROFILE_ID } from "../../../../utils/constants";
// const onChange: DatePickerProps<Dayjs[]>['onChange'] = (date, dateString) => {
// }
// dayjs.extend(customParseFormat);
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const WsCreate = (props: any) => {
  const { auth, wareHouseOut, tracking, company } = useSelector(
    (state: any) => state
  );
  const [open, setOpen] = React.useState<any>(false);
  const [selectedProductId, setSelectedProductId] = React.useState<any>("");
  const [selectedProduct, setSelectedProduct] = React.useState<any>("");
  const [showDeliverer, setShowDeliverer] = React.useState<any>("");
  const [delivererId, setDeliverer] = React.useState<any>("");
  const [trackingType, setTrackingType] = React.useState<any>("");
  const [serialNoString, setSerialNoString] = React.useState<any>([]);
  const [duplicateItems, setDuplicateItems] = React.useState<any>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  localStorage.setItem("pr_id", selectedProduct?._id);
  const productIdFromLocalStorage = localStorage.getItem("pr_id");
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleOtpCancel = () => {
    setIsOtpModalOpen(false);
  };

  const showOtpModal = () => {
    setIsOtpModalOpen(true);
  };

  const handleOtpOk = () => {
    setIsOtpModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const trId = wareHouseOut?.shippingDetails?.data?._id;
  const warehouseOutData = wareHouseOut?.warehouseOut?.data[0];
  function calculateDistance(lat1: any, lon1: any, lat2: any, lon2: any) {
    const R = 6371;
    const toRadians = (degrees: any) => degrees * (Math.PI / 180);
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }
  const companyId = COMPANY_PROFILE_ID;
  const distances = calculateDistance(
    company?.selected?.warehouse[0]?.shopLocation?.coordinate?.lat,
    company?.selected?.warehouse[0]?.shopLocation?.coordinate?.lng,
    warehouseOutData?.stockRequest?.shopId?.shopLocation?.coordinate?.lat,
    warehouseOutData?.stockRequest?.shopId?.shopLocation?.coordinate?.lng
  );
  useEffect(() => {
    auth?.token &&
      getOneCompanyAction(companyId as string, auth.token)(dispatch);
    auth?.token && getTrackingTypeAction(auth?.token)(dispatch);
  }, [dispatch, auth?.token]);
  useEffect(() => {
    auth?.token && getDelivererAction(auth?.token)(dispatch);
  }, [dispatch, auth?.token]);
  const IntransitParchage = async () => {
    await updateOneStockOutDetailsAction(
      auth?.token,
      wareHouseOut?.warehouseOut?.data[0]?._id,
      "",
      {
        status: "In-Transit",
      }
    )(dispatch);
    await updateParkingAction(
      auth?.token,
      {
        trackingStatus: "In-Transit",
      },
      wareHouseOut?.warehouseOut?.data[0]?.trackingId
    )(dispatch);
    await createDeliveryActivityAction(auth?.token, {
      name: "In-Transit",
      status: "In-Transit",
      details: "The Parkage is in Transit",
      trackId: wareHouseOut?.warehouseOut?.data[0]?.trackingId,
    })(dispatch);
    notification.success({ message: "updated successfully" });
    auth?.token &&
      productIdFromLocalStorage &&
      getAllWarehouseOutItemsAction(
        auth?.token,
        `requestedItem=${productIdFromLocalStorage}&limit=2000`
      )(dispatch);
    auth?.token &&
      id &&
      getAllWarehouseOutAction(auth?.token, `?warehouseOutId=${id}`)(dispatch);
    // navigate(-1);
  };
  const deliveredParchage = async () => {
    await updateOneStockOutDetailsAction(
      auth?.token,
      wareHouseOut?.warehouseOut?.data[0]?._id,
      "",
      {
        status: "Delivered",
      }
    )(dispatch);
    await updateParkingAction(
      auth?.token,
      {
        trackingStatus: "Delivered",
      },
      wareHouseOut?.warehouseOut?.data[0]?.trackingId
    )(dispatch);
    await createDeliveryActivityAction(auth?.token, {
      name: "Delivered",
      status: "Delivered",
      details: "The Parkage is delivered",
      trackId: wareHouseOut?.warehouseOut?.data[0]?.trackingId,
    })(dispatch);
    notification.success({ message: "updated successfully" });
    auth?.token &&
      productIdFromLocalStorage &&
      getAllWarehouseOutItemsAction(
        auth?.token,
        `requestedItem=${productIdFromLocalStorage}&limit=2000`
      )(dispatch);
    auth?.token &&
      id &&
      getAllWarehouseOutAction(auth?.token, `?warehouseOutId=${id}`)(dispatch);
    navigate(-1);
  };
  const onFinish = async (values: any) => {
    // const handleDeliverer = async () => {
    if (auth?.token) {
      const res = await createShippingDetailsAction(auth?.token, {
        deliver: delivererId,
        deliveryNote: {
          id: wareHouseOut?.warehouseOut?.data[0]?._id,
          getLink: `/shopstock/one/${wareHouseOut?.warehouseOut?.data[0]?._id}`,
        },
        locationFrom: {
          name: company?.selected?.name,
          coordinate: {
            lat: company?.selected?.warehouse[0]?.shopLocation?.coordinate?.lat,
            lng: company?.selected?.warehouse[0]?.shopLocation?.coordinate?.lng,
          },
        },
        locationTo: {
          name: warehouseOutData?.stockRequest?.shopId?.name,
          coordinate: {
            lat: warehouseOutData?.stockRequest?.shopId?.shopLocation
              ?.coordinate?.lat,
            lng: warehouseOutData?.stockRequest?.shopId?.shopLocation
              ?.coordinate?.lng,
          },
        },
        distance: distances,
        trackingType: trackingType,
        type: "To shop",
        ...values,
      })(dispatch);
      if (res?.type) {
        await updateOneStockOutDetailsAction(
          auth?.token,
          wareHouseOut?.warehouseOut?.data[0]?._id,
          selectedProduct?._id,
          {
            deliverer: {
              name: tracking?.selected?.name,
              phone: tracking?.selected?.phone,
              company: tracking?.selected?.company,
              email: tracking?.selected?.email,
            },
            status: "Ready For Pickup",
            trackingId: res?.data?._id,
            // status: "In-Transit",
          }
        )(dispatch);

        await createDeliveryActivityAction(auth?.token, {
          name: "Initialization",
          status: "Ready For Pickup",
          details: "Device is ready for Pickup",
          trackId: res?.data?._id,
        })(dispatch);
        await createOtpActions(auth?.token, {
          email: tracking?.selected?.email,
          phoneNumber: tracking?.selected?.phone,
          name: tracking?.selected?.name,
        })(dispatch);
        // res && navigate(-2);
      } else {
        notification.error({ message: "Error Occured Please try again" });
      }
    }

    auth?.token &&
      productIdFromLocalStorage &&
      getAllWarehouseOutItemsAction(
        auth?.token,
        `requestedItem=${productIdFromLocalStorage}&limit=2000`
      )(dispatch);
    auth?.token &&
      id &&
      getAllWarehouseOutAction(auth?.token, `?warehouseOutId=${id}`)(dispatch);
    form.resetFields();
  };
  useEffect(() => {
    setShowDeliverer(
      wareHouseOut?.selectedWarehouseOut &&
        !wareHouseOut?.selectedWarehouseOut?.stockRequest?.list
          ?.map(
            (item: any, _index: number) =>
              item?.qtyApproved === item?.qtyRequested
          )
          ?.includes(false)
    );
  }, [wareHouseOut?.selectedWarehouseOut]);

  useEffect(() => {
    auth?.token &&
      getAllWarehouseOutAction(auth?.token, `?warehouseOutId=${id}`)(dispatch);
  }, [auth?.token, dispatch, id]);

  const handleSetSelected = (product_id: string) => {
    setSelectedProductId(product_id);
    setSelectedProduct(
      wareHouseOut?.selectedWarehouseOut?.stockRequest?.list?.filter(
        (item: any) => item?._id === product_id
      )[0]
    );
    auth?.token &&
      product_id &&
      getAllWarehouseOutItemsAction(
        auth?.token,
        `requestedItem=${product_id}&limit=2000`
      )(dispatch);
  };
  const handleSubmit = async () => {
    // await handleGetStockBySN(serialNoArr);
    form.resetFields();
  };
  //get product by Serial number
  const handleGetStockBySN = async (searchSerialNumber: String) => {
    if (auth?.token && searchSerialNumber) {
      const res = await getAllWarehouseActionBySN(
        auth?.token,
        `?serialNumber=${searchSerialNumber}&status=in`
      )(dispatch);
      if (res) {
        form.resetFields();
        res?.length === 0 &&
          notification.error({
            message: "Product Not Found!",
            description: `Product with S/N ${searchSerialNumber} is not available in the warehouse. `,
          });
        if (res?.length === 1) {
          const resAdd = await updateStockOutAction(
            wareHouseOut?.selectedWarehouseOut?._id,
            {
              product: res[0]?.quotationItem?.product?._id,
              productPriceList: selectedProduct?.product?._id,
              requestedItem: selectedProduct?._id,
              stockRequest:
                wareHouseOut?.selectedWarehouseOut?.stockRequest?._id,
              wareHouseItem: res[0]?._id,
              shop: wareHouseOut?.selectedWarehouseOut?.stockRequest?.shopId
                ?._id,
            },
            auth?.token
          )(dispatch);

          resAdd &&
            notification.success({
              message: "Product Is Added!",
              description: `Product with S/N ${searchSerialNumber} is  available in the warehouse. `,
            });
        }
        // se}lectedOnestock
      }
    }
  };

  const handleAddProducts = async () => {
    if (
      serialNoString?.trim().split(" ")?.length <=
      selectedProduct?.qtyApproved -
        wareHouseOut?.warehouseOutItem?.data?.length
    ) {
      if (
        new Set(serialNoString?.trim().split(" ")).size !==
        serialNoString?.trim().split(" ")?.length
      ) {
        setDuplicateItems(
          serialNoString
            .trim()
            ?.split(" ")
            ?.filter(
              (value: any, index: any) =>
                serialNoString.trim()?.split(" ").indexOf(value) !== index
            )
        );
        return notification.warning({
          message: "IMEI has been duplicated",
          description: `${serialNoString
            ?.split(" ")
            ?.filter(
              (value: any, index: any) =>
                serialNoString?.toString()?.trim().split(" ").indexOf(value) !==
                index
            )
            .join(" , ")}`,
        });
      }

      await updateStockOutAction(
        wareHouseOut?.selectedWarehouseOut?._id,
        {
          action: wareHouseOut?.selectedWarehouseOut?.isTransferred
            ? "transfer"
            : "warehouseout",
          product: selectedProduct?.product?.product?._id,
          serialNumbers: serialNoString?.toString()?.trim().split(" "),
          productPriceList: selectedProduct?.product?._id,
          requestedItem: selectedProduct?._id,
          stockRequest: wareHouseOut?.selectedWarehouseOut?.stockRequest?._id,
          shop: wareHouseOut?.selectedWarehouseOut?.stockRequest?.shopId?._id,
        },
        auth?.token
      )(dispatch);
    } else {
      notification.error({
        message: `Please Verify only ${
          selectedProduct?.qtyApproved -
          (wareHouseOut?.warehouseOutItem?.data?.length ?? 0)
        } Devices`,
      });
    }
  };
  const location = useLocation();
  const onSearch = () => {
    getDelivererAction("")(dispatch);
  };
  const [form] = Form.useForm();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChangeDeliverer = (value: any) => {
    setDeliverer(value);
    auth?.token && getOneDelivererAction(value, auth?.token)(dispatch);
  };
  const onChangeType = (value: string) => {
    setTrackingType(value);
  };
  const trackingTypeOptions = tracking?.trackingType?.data?.map((el: any) => ({
    value: el?._id,
    label: el?.name,
  }));
  const filteredTrackingTypeOptions = location.pathname?.includes(
    "inventory/ws"
  )
    ? trackingTypeOptions?.filter((option: any) => option?.label === "locally")
    : trackingTypeOptions;
  const delivererOptions =
    tracking?.allDeliverer?.data?.map((el: any) => ({
      value: el?._id,
      label: `${el?.name} ${el?.phone}`,
    })) || [];

  const getDelivererBySearch = async (searchValue: any) => {
    await getDelivererAction(auth?.token, `searchKey=${searchValue}`)(dispatch);
  };
  // const onSearchDeliverer = (value:any) => {
  //   if (value) {
  //     getDelivererBySearch(value);
  //   }
  // };
  return (
    <div className="text-black p-5 mt-3 space-y-2">
      <DetailsHeaderActionBar pageName={props?.pageName} title={"Out"} />
      <div className="p-5 bg-white rounded-lg space-y-6">
        <div className="flex relative gap-20 w-full justify-between items-end">
          <Stack
            direction={"column"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              className="w-full"
            >
              <div className="mb-5">
                <p className="text-xsx text-gray-400">
                  {`${warehouseOutData?.stockRequest?.shopId?.name}`}
                </p>
                <p className="text-xsx text-gray-400">
                  {warehouseOutData?.stockRequest?.shopId?.channel?.name}
                </p>
              </div>
              <Tag
                color={
                  wareHouseOut?.selectedWarehouseOut?.isTransferred
                    ? "blue"
                    : "yellow"
                }
              >
                {wareHouseOut?.selectedWarehouseOut?.isTransferred
                  ? "As Transfer"
                  : "From Warehouse"}
              </Tag>
              <div></div>
              <p className="text-xsx text-gray-400 border border-[#FFC727] py-0.5 px-4 rounded-md">
                {wareHouseOut?.selectedWarehouseOut?.status}
              </p>
            </Stack>

            {wareHouseOut?.selectedWarehouseOut?.status !==
              "Ready For Pickup" && (
              <div className="w-max ">
                <p className="w-fit font-bold text-lg">Model name</p>
                <div className="flex gap-5 text-gray-300 items-center">
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Choose Product Model"
                    onChange={(data: any) => {
                      handleSetSelected(data);
                    }}
                    options={wareHouseOut?.selectedWarehouseOut?.stockRequest?.list?.map(
                      (item: any, _index: number) => ({
                        label:
                          `${
                            item?.qtyApproved === item?.qtyRequested
                              ? "✔️ "
                              : ""
                          }  ` + item?.product?.product?.model,
                        value: item?._id,
                      })
                    )}
                  />

                  {wareHouseOut.isFetching && (
                    <div className="flex h-fit ">
                      <Spin indicator={antIcon} />
                    </div>
                  )}

                  {!wareHouseOut.isFetching && (
                    <span>{`${
                      wareHouseOut?.selectedWarehouseOut?.stockRequest?.list?.filter(
                        (item: any) => item?.qtyApproved === item?.qtyRequested
                      )?.length
                    }/${
                      wareHouseOut?.selectedWarehouseOut?.stockRequest?.list
                        ?.length
                    }`}</span>
                  )}
                </div>
                {
                  <div className="grid grid-cols-2 min-w-[288px] bg-slate-100 mt-2 rounded-md p-3">
                    <>
                      {selectedProduct?.product?.specification?.map(
                        (item: any, _index: number) =>
                          item[1] && (
                            <>
                              <span className="font-semibold">{item[0]}</span>
                              <span>{item[1]}</span>
                            </>
                          )
                      )}
                    </>
                  </div>
                }
              </div>
            )}
          </Stack>

          <Modal
            title={
              <h1 className="text-[#030229] text-lg py-2">Create Deliverer</h1>
            }
            width={800}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
          >
            <CreateDelivererForm onOk={handleOk} />
          </Modal>
          <Modal
            title={
              <h1 className="text-[#030229] text-lg py-2">Confirm With Otp</h1>
            }
            width={800}
            open={isOtpModalOpen}
            onOk={handleOtpOk}
            onCancel={handleOtpCancel}
            footer={null}
          >
            <VerifyOtpForm
              onOk={handleOtpOk}
              selectedP={selectedProduct?._id}
              phone={tracking?.selected?.phone}
              data={tracking?.selected}
              from="warehouseOut"
            />
          </Modal>
          {wareHouseOut?.warehouseOutItem?.data?.length <
            selectedProduct?.qtyApproved &&
            selectedProduct?.qtyRequested && (
              <div className=" flex gap-0">
                <Stack
                  direction="row"
                  // justifyContent="flex-end"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Stack marginTop={"10px"}>
                    <span>{`${
                      wareHouseOut?.warehouseOutItem?.data?.length || 0
                    }/${selectedProduct?.qtyApproved}`}</span>
                    <Input
                      style={{ width: 400 }}
                      placeholder="Search Serial Number"
                      onChange={(e: any) => setSerialNoString(e.target.value)}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="end"
                    alignItems="end"
                    sx={{ width: 80 }}
                  >
                    {!wareHouseOut?.isFetching && (
                      <Button
                        // type="submit"
                        disabled={wareHouseOut?.isFetching}
                        style={{
                          backgroundColor: "blue",
                          color: "white",
                          marginLeft: "4px",
                        }}
                        onClick={() => handleAddProducts()}
                      >
                        Verify
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </div>
            )}
          {warehouseOutData?.isTransferred && (
            <div className="absolute right-1 top-32">
              <ShopFromDrower
                showDrawer={showDrawer}
                open={open}
                onClose={onClose}
              />
            </div>
          )}
        </div>
        {wareHouseOut?.selectedWarehouseOut?.status === "Ready For Pickup" ? (
          <WarehouseOutTableView />
        ) : (
          <WarehouseOutTable selectedProductId={selectedProductId} />
        )}
        {wareHouseOut?.selectedWarehouseOut?.status === "Pending" &&
          (selectedProduct || productIdFromLocalStorage) &&
          wareHouseOut?.warehouseOutItem?.data?.length > 0 &&
          wareHouseOut?.warehouseOutItem?.data?.length ===
            selectedProduct?.qtyApproved && (
            <div className="space-y-4">
              <p className="text-[#030229] text-lg">Deliverer Details</p>
              <Form onFinish={onFinish} form={form} className="w-full">
                <div className="w-1/3 flex gap-20">
                  <div className="w-full">
                    <Form.Item
                      name="trackingType"
                      label={
                        <h1 className="text-[#030229B2] text-base">
                          Tracking Type
                        </h1>
                      }
                      rules={[
                        {
                          required: true,
                          message: "tracking type is required!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a tracking type"
                        optionFilterProp="label"
                        onSearch={getDelivererBySearch}
                        onChange={onChangeType}
                        options={trackingTypeOptions}
                        loading={tracking?.isFetching}
                      />
                    </Form.Item>
                    <Form.Item
                      name="deliverer"
                      label={
                        <h1 className="text-[#030229B2] text-base">
                          Select deliverer
                        </h1>
                      }
                      // initialValue={deliverer?.phone}
                      rules={[
                        {
                          required: true,
                          message: "Deliverer is required!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Select
                        onSearch={onSearch}
                        showSearch
                        placeholder="Select deliverer"
                        optionFilterProp="label"
                        onChange={onChangeDeliverer}
                        options={delivererOptions}
                        loading={tracking?.isFetching}
                        dropdownRender={(menu) => (
                          <div>
                            {menu}
                            <Divider></Divider>
                            <Button
                              disabled={
                                wareHouseOut?.isFetching || tracking?.isFetching
                              }
                              style={{
                                backgroundColor: "#605BFF",
                                color: "white",
                                textAlign: "center",
                                width: "100%",
                              }}
                              onClick={showModal}
                              className="bg-[#605BFF] h-10 text-base text-white text-center w-full rounded-md"
                            >
                              + Create New Deliverer
                            </Button>
                          </div>
                        )}
                      />
                    </Form.Item>
                  </div>
                </div>
                <LoadingButton
                  type="submit"
                  disabled={wareHouseOut?.isFetching || tracking?.isFetching}
                  variant="contained"
                  sx={{ width: "34%" }}
                  loading={wareHouseOut?.isFetching || tracking?.isFetching}
                >
                  Save
                </LoadingButton>
              </Form>
            </div>
          )}
        {wareHouseOut?.selectedWarehouseOut?.status === "Ready For Pickup" && (
          <Button
            disabled={wareHouseOut?.isFetching || tracking?.isFetching}
            className="bg-[#605BFF] text-white px-4 py-1 rounded-md"
            style={{
              backgroundColor: "#605BFF",
              color: "white",
              textAlign: "center",
              // width:"100%"
            }}
            onClick={showOtpModal}
          >
            Confirm Pickup
          </Button>
        )}
        {wareHouseOut?.selectedWarehouseOut?.status === "Pickup" && (
          <Button
            disabled={wareHouseOut?.isFetching || tracking?.isFetching}
            // className="bg-[#605BFF] text-white px-4 py-1 rounded-md"
            style={{
              backgroundColor: "#605BFF",
              color: "white",
              textAlign: "center",
              // width:"100%"
            }}
            onClick={IntransitParchage}
          >
            {wareHouseOut?.isFetching ? (
              <Spin indicator={antIcon} />
            ) : (
              "Release for Transit"
            )}
          </Button>
        )}
        {wareHouseOut?.selectedWarehouseOut?.status === "In-Transit" && (
          <Button
            disabled={wareHouseOut?.isFetching || tracking?.isFetching}
            className="bg-[#605BFF] text-white px-4 py-1 rounded-md"
            style={{
              backgroundColor: "#605BFF",
              color: "white",
              textAlign: "center",
              // width:"100%"
            }}
            onClick={deliveredParchage}
          >
            {wareHouseOut?.isFetching ? (
              <Spin indicator={antIcon} />
            ) : (
              "Confirm Delivery"
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default WsCreate;
