import { Stack, Button } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import { Select } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ebmActions } from "../../store";
import {
  getAllProductsAction,
  getAllProductsAttributesAction,
} from "../../store/action";
import { getEbmSalesAction } from "../../store/SalesManagement/actions";
const { Option } = Select;

const FilterSales = (props: any) => {
  const { product, auth, channel, ebmGeneral } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");
  const [productId, setProductId] = useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(40);

  useEffect(() => {
    dispatch(
      ebmActions.setQuery(
        `?${type && `type=${type}${brand && `&brand=${brand}`}&`}`
      )
    );
  }, [brand, dispatch, type]);

  const resetFilter = () => {
    if (selectedFilters) {
      setType("");
      setBrand("");
      setProductId("");
      props.setBrandData("");
      props.setItemForm(false);
    }
    // if (props.open === true) {
    //   auth?.token &&
    //     getPricelistItemAction(
    //       auth?.token,
    //       `?limit=15${
    //         channel?.selected?._id ? `&channel=${channel?.selected?._id}` : ""
    //       }`
    //     )(dispatch);
    // }
  };

  useEffect(() => {
    const fetchingData = async () => {
      auth?.token &&
        getAllProductsAction(
          auth?.token,
          ebmGeneral?.query +
            `limit=${ebmGeneral?.allProducts?.total}&page=${page - 1}` || "?"
        )(dispatch);
      auth?.token &&
        (await getAllProductsAttributesAction(
          auth?.token,
          ebmGeneral?.query || "?"
        )(dispatch));
    };
    fetchingData();
  }, [auth, ebmGeneral?.query, dispatch, limit, page]);

  useEffect(() => {
    productId &&
      auth?.token &&
      getEbmSalesAction(
        auth?.token,
        `?type=${type}&brand=${brand}&model=${productId}&limit=15&page=0`
      )(dispatch);
  }, [auth?.token, dispatch, productId]);

  useEffect(() => {
    if (productId === " ") {
      auth?.token &&
        getEbmSalesAction(
          auth?.token,
          `?channel=${channel?.selected?._id}&limit=15`
        )(dispatch);
    }
  }, [productId]);

  return (
    <div className="px-4 py-2">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          spacing={1}
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {selectedFilters ? (
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                fontSize: "15px",
                background: "#e8ecfa",
                color: "blue",
              }}
              startIcon={<IoFilterOutline />}
              onClick={() => {
                setSelectedFilters(!selectedFilters);
                resetFilter();
              }}
            >
              Filter
            </Button>
          ) : (
            <Button
              style={{
                textTransform: "none",
                fontSize: "15px",
                color: "black",
              }}
              startIcon={<IoFilterOutline />}
              onClick={() => {
                setSelectedFilters(!selectedFilters);
                resetFilter();
              }}
            >
              Filter
            </Button>
          )}

          {selectedFilters && (
            <Stack
              spacing={1}
              direction="row"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Select
                showSearch
                style={{ width: 300, height: 40 }}
                allowClear
                placeholder="Filter By Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                loading={ebmGeneral.isFetching}
                disabled={ebmGeneral.isFetching}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                options={ebmGeneral?.attributes?.types}
                onChange={(value: any) => {
                  !value && setType("");
                  !value && setBrand("");
                  !value && setProductId(" ");
                  value && setType(value);
                }}
              />
              {type && (
                <Select
                  loading={ebmGeneral.isFetching}
                  disabled={ebmGeneral.isFetching}
                  showSearch
                  allowClear
                  style={{ width: 300, height: 40 }}
                  placeholder="Filter By Brand"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")

                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  options={ebmGeneral?.attributes?.brands}
                  onChange={(value: any) => {
                    !value && setBrand("");
                    !value && setProductId(" ");
                    value && setBrand(value);
                  }}
                />
              )}
              {brand && (
                <Select
                  style={{ width: 300, height: 40 }}
                  loading={ebmGeneral.isFetching || channel.isFetching}
                  disabled={ebmGeneral.isFetching || channel.isFetching}
                  showSearch
                  allowClear
                  placeholder="Filter By Product"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")

                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  onChange={(value: any) => {
                    !value && setProductId(" ");
                    value && setProductId(value);
                    value && props.setBrandData(value);
                  }}
                  // onSearch={onSearch}
                >
                  {ebmGeneral?.allProducts?.data?.map((el: any) => (
                    <Option value={el?.model}>{el?.model}</Option>
                  ))}
                </Select>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default FilterSales;
